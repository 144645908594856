



























































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {validationMixin} from 'vuelidate';
import {namespace} from 'vuex-class';
import {email, maxLength, minValue, required, numeric, minLength} from 'vuelidate/lib/validators';
import Company from '@/models/Company';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {mixins} from 'vue-class-component';
import User from '@/models/User';
import UserRole from '@/models/user-attributes/UserRole';
import {CountryStorage} from '@/misc/CountryStorage';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import RJSelect from '@/components/shared/custom-vuetify/RJSelect.vue';
import {LCircle, LMap, LMarker, LTileLayer} from 'vue2-leaflet';
import AddressWithMapComponent from '@/components/shared/AddressWithMap.component.vue';
import Address from '@/models/Address';


const CompanyStore = namespace('company');
const UserStore = namespace('user');


@Component({
  components: {
    RJSelect, RJTextField,
    AddressWithMapComponent,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
  },
  mixins: [validationMixin],
  validations: {
    companyCopy: {
      prefix: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(5),
      },
      name: {required},
      address: {
        street: {required},
        houseNo: {required, maxLength: maxLength(15)},
        postalCode: {required, numeric, maxLength: maxLength(5)},
        city: {required},
        country: {required},
      },
      maxUsers: {required, minValue: minValue(1)},
    },
    manager: {
      email: {required, email},
      firstName: {required},
      lastName: {required},
      phone: {required, minLength: minLength(3)},
    },
  },
})
export default class CompanyManageComponent extends mixins(ErrorMessageHandlerMixin) {

  @Prop({default: () => new Company()})
  public company!: Company;

  @Prop({default: true})
  public managerForm!: boolean;

  public companyCopy: Company = new Company();
  public showComponent: boolean = false;

  public manager!: User;

  public hasSameEmail: boolean = false;
  public submitted: boolean = false;
  public stateButtonAvailable: boolean = false;
  public countries: Array<{ text: string, value: string }> = [];
  private hasSamePrefix: boolean = false;
  private hasSameName: boolean = false;
  @CompanyStore.Action('createCompanyAction')
  private createCompany!: (company: Company) => any;
  @UserStore.Action('createUserAction')
  private createUserAction!: (user: User) => Promise<User>;
  @CompanyStore.Action('editCompanyAction')
  private editCompany!: (company: Company) => any;
  @CompanyStore.Mutation('storeCompany')
  private storeCompany!: (company: Company) => any;
  @UserStore.Action('loadUserByEmailAction')
  private loadUserByEmail!: (email: string) => Promise<User[]>;
  @UserStore.Action('loadUsersAction')
  private loadUsersAction!: (payload: { companyId: string }) => Promise<User[]>;
  @UserStore.Action('loadUserRolesAction')
  private loadUserRoles!: (payload: { companyId: string }) => Promise<UserRole[]>;
  @UserStore.Action('editUserAction')
  private editUser!: (user: User) => any;
  @UserStore.Getter('users')
  private _users!: User[];

  private get companyUsers() {
    return this._users;
  }

  constructor() {
    super();
    this.manager = new User();
    this.showComponent = true;
  }

  public async created() {
    this.countries = CountryStorage;
    this.stateButtonAvailable = this.$userRoleHandler.isSuperAdmin()
      && this.companyCopy.id != null;

    if (this.company.id) {
      await this.loadUsersAction({companyId: this.company.id});
    }
  }

  /**
   * Event handler that saves the edition or the creation of company object
   */
  public async onSubmit() {
    this.hasSameName = false;
    this.hasSamePrefix = false;
    this.submitted = true;
    if (!this.managerForm && !this.manager.id) {
      this.manager = this.companyManager;
    }
    // trigger validation
    this.$v.$touch();

    if (this.$v.$invalid) {
      // if invalid scroll to first error input
      // OPTIMIZE the use of an css selector of a vuetify property is not always safe
      requestAnimationFrame(() => this.$vuetify.goTo('.error--text', {offset: 50}));
      this.submitted = false;
    } else {
      try {
        if (this.companyCopy!.id) {
          // if no manager is changed, no need to update him
          if (this.managerForm) {
            await this.editUser(this.manager);
          }
          await this.editCompany(this.companyCopy!);
          this.$notifySuccessSimplified('COMPANY.NOTIFICATIONS.COMPANY_EDITED');
          this.$emit('exitModal');
        } else {
          // 1. create company 2. create user with company association and set role

          // Manager already exsists => user has to change his email
          const existingManager = await this.loadUserByEmail(this.manager.email!);

          if (existingManager.length > 0) {
            this.hasSameEmail = true;
            return this.$notifyErrorSimplified('COMPANY.NOTIFICATIONS.MANAGER_ALREADY_EXISTS');
          }

          this.hasSameEmail = false;

          const createdCompany: Company = await this.createCompany(this.companyCopy!);
          // set manager companyId to the created companyId
          this.manager.companyId = createdCompany.id;
          // load roles of this company
          const userRoles = await this.loadUserRoles({companyId: createdCompany.id});
          // assign tenantAdmin role to user
          this.manager.roleId = userRoles.find((role) => role.isTenantAdmin)?.id;
          delete this.manager.role;
          // ...and create the user
          await this.createUserAction(this.manager);

          // set user count to 1 to avoid NaN
          createdCompany.usersCount = 1; // 1 means the manager
          this.storeCompany(createdCompany);

          this.$emit('exitModal');
          this.$notifySuccessSimplified('COMPANY.NOTIFICATIONS.COMPANY_CREATED');
        }
      } catch (e: any) {
        if (e.status === 409) {
          this.hasSameName = true;
        } else if (e.status === 406) {
          this.hasSamePrefix = true;
        }
        if (this.$te(`COMPANY.NOTIFICATIONS.${e.status}.TITLE`)) {
          this.$notifyErrorSimplified(`COMPANY.NOTIFICATIONS.${e.status}`);
        } else {
          this.$notifyErrorSimplified(`GENERAL.NOTIFICATIONS.GENERAL_ERROR`);
        }
      }
    }
  }

  public cancelButtonClicked() {
    this.$emit('close');
  }

  public async stateButtonClicked() {
    try {
      this.companyCopy.active = !this.companyCopy.active;
      await this.editCompany(this.companyCopy);

      if (this.companyCopy.active) {
        this.$notifySuccessSimplified('COMPANY.NOTIFICATIONS.COMPANY_ACTIVATED');
      } else {
        this.$notifySuccessSimplified('COMPANY.NOTIFICATIONS.COMPANY_DEACTIVATED');
      }
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  public setAddress(event: Address) {
    this.companyCopy.address!.street = event.street;
    this.companyCopy.address!.postalCode = event.postalCode;
    this.companyCopy.address!.houseNo = event.houseNo;
    this.companyCopy.address!.city = event.city;
    this.companyCopy.address!.geoPosition = event.geoPosition;
  }


  @Watch('company')
  private async onCompanyChange() {
    this.companyCopy = Company.parseFromObject(this.company.parseToObject());
    if (this.companyCopy!.id) {
      this.manager = this.companyManager;
    }
  }

  private get companyManager() {
    return this.companyUsers
      .find((user) => user.roleId === this.companyCopy.roles.find((role) => role.isTenantAdmin)!.id) ?? new User();
  }
}
